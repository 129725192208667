<template>
    <div>
        <!-- <footer id="footer_area" class="bg-primary text-white">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-6 col-sm-6 col-12">
                        <div class="footer_heading_area ">
                            <h5>Precisa de ajuda?</h5>
                        </div>
                        <div class="footer_first_area text-white" style="margin-left: -.6rem">
                            <div class="footer_inquery_area">
                                <h5>Ligue 24 horas por dia, 7 dias por semana para qualquer ajuda</h5>
                                <h3> <a href="tel:+55 3333-3333" class="text-white">+55 3333-333</a></h3>
                            </div>
                            <div class="footer_inquery_area text-white">
                                <h5>Envie para nossa equipe de suporte</h5>
                                <h3> <a href="mailto:support@clubekaboo.com" class="text-white">support@clubekaboo.com</a></h3>
                            </div>
                            <div class="footer_inquery_area">
                                <h5>Siga-nos</h5>
                                <ul class="soical_icon_footer">
                                    <li><a href="#!"><i class="fab fa-facebook text-white"></i></a></li>
                                    <li><a href="#!"><i class="fab fa-twitter-square text-white"></i></a></li>
                                    <li><a href="#!"><i class="fab fa-instagram text-white"></i></a></li>
                                    <li><a href="#!"><i class="fab fa-linkedin text-white"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>Empresa</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><router-link to="#" class="text-gray-200 font-weight-light">Sobre nós</router-link></li>
                                <li><router-link to="#">Depoimentos</router-link></li>
                                <li><router-link to="#">FAQS</router-link></li>
                                <li><router-link to="#">Termo de serviço</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <div class="footer_heading_area">
                            <h5>Support</h5>
                        </div>
                        <div class="footer_link_area">
                            <ul>
                                <li><router-link to="#">Conta</router-link></li>
                                <li><router-link to="#">FAQ</router-link></li>
                                <li><router-link to="#">Depoimentos</router-link></li>
                                <li><router-link to="#">Contato</router-link></li>
                                <li><router-link to="#"> Programa de Afiliados </router-link></li>
                                <li><router-link to="#">Politica de privacidade</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer> -->
        <div class="copyright_area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="copyright_left">
                            <p>Copyright © 2024 todos os direitos reservados</p>
                        </div>
                    </div>
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="copyright_right">
                            Club Kaboo
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Footer'
}
</script>
